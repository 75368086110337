<template>
<button 
class="sh-button"
@click="handleClick"
:disabled="disabled || loading"
:type="type"
:class="[
    type ? 'sh-button--' + type : '',
    size ? 'sh-button--' + size : '',
    {
    'is-disabled': disabled,
    'is-loading': loading,
    'is-round': round,
    'is-circle': circle
    }
]">
    <!-- <i class="sh-icon-loading" v-if="loading"></i> -->
    <i :class="icon" v-if="icon && !loading"></i>
    <slot v-if="$slots.default"></slot>
</button>
</template>

<script>
export default {
    name: 'ShButton',
    props: {
        type: {
            type: String,
            default: 'default'
        },
        size: String,
        icon: {
            type: String,
            default: ''
        },
        loading: Boolean,
        disabled: Boolean,
        round: Boolean,
        circle: Boolean
    },
    data () {
        return {

        }
    },
    computed: {

    },
    methods: {
        handleClick(evt) {
            this.$emit('click', evt);
        }
    },
    created () {

    },
    mounted () {

    },
    beforeDestroy () {
        
    },
    watch: {

    }
}
</script>

<style lang="scss" scoped>
// @import '@/assets/styles/config.scss';

.sh-button {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background-color: #017dfa;
    border: 0;
    color: #fff;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
    &:hover, &:focus {
        background-color: #66b1ff;
    }
    &:active {
        background-color: #3a8ee6;
    }
    &--danger {
        background-color: #F56C6C;
        &:hover, &:focus {
            background-color: #f78989;
        }
        &:active {
            background-color: #dd6161;
        }
    }
    &--primary {
        color: #3a8ee6;
        background-color: #ffffff;
        // border: 1px solid #dcdfe6;
        &:hover, &:focus {
            background-color: #c6e2ff;
            color: #3a8ee6;
        }
        &:active {
            // background-color: #dd6161;
            color: #3a8ee6;
        }
    }
    &--mini {
        font-size: 12px;
        padding: 7px 15px;
    }
    &--small {
        padding: 9px 15px;
    }
    & + & {
        margin-left: 10px;
    }
    &.is-round {
        border-radius: 20px;
    }
    &.is-disabled {
        color: #fff;
        background-color: #c8c9cc;
        cursor: not-allowed;
    }
    &.is-circle {
        border-radius: 50%;
        padding: 12px;
    }
}
</style>
