<template>
  <div class="warp">
    <div class="ipt">
      <sh-input v-model="name" placeholder="请输入用户名"></sh-input>
      <sh-input v-model="pwd" type="password" placeholder="请输入密码"></sh-input>
      <sh-button :loading="loding" @click="handleLogin" round>确定</sh-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import crypto from 'crypto'
import ShInput from '@/components/uikit/input'
import ShButton from '@/components/uikit/button'

export default {
  name: 'Login',
  components: {
    ShInput,
    ShButton
  },
  data() {
    return {
      name: '',
      pwd: '',
      loding: false
    }
  },
  methods: {
    ...mapActions([
      'logIn',
      'getUserInfo'
    ]),
    handleLogin() {
      this.loding = true
      let hash = crypto.createHash('sha256')
      let password = hash.update(this.pwd).digest('hex')
      const params = {
        name: this.name,
        password: password
      }
      this.logIn(params).then((res) => {
        // this.loding = false
        this.getUserInfo().then(res => {
          this.loding = false
          this.$router.push({
            name: 'home'
          })
        })
        // if(res.roles === 0) {
        //   this.$router.push({
        //     name: 'management'
        //   })
        // } else {
        //   this.$router.push({
        //     name: 'home'
        //   })
        // }
        
      }).catch(err => {
        this.loding = false
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.warp {
  width: 100%;
  // height: calc(100% - 246px);
  padding: 200px 0;
  .ipt {
    width: 600px;
    padding: 80px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #182760;
    margin: 0 auto;
    &>div {
      margin: 5px 0;
    }
    button {
      width: 100%;
      margin-top: 20px;
    }
  }
}
@media (max-width: 767px) {
  .warp {
    padding: 200px 20px;
    .ipt {
      width: 100%;
    }
  }
}
</style>
