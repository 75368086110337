var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"sh-button",class:[
    _vm.type ? 'sh-button--' + _vm.type : '',
    _vm.size ? 'sh-button--' + _vm.size : '',
    {
    'is-disabled': _vm.disabled,
    'is-loading': _vm.loading,
    'is-round': _vm.round,
    'is-circle': _vm.circle
    }
],attrs:{"disabled":_vm.disabled || _vm.loading,"type":_vm.type},on:{"click":_vm.handleClick}},[(_vm.icon && !_vm.loading)?_c('i',{class:_vm.icon}):_vm._e(),(_vm.$slots.default)?_vm._t("default"):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }