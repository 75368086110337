<template>
<div :class="[
'sh-input',
{
    'sh-input-group': $slots.prepend || $slots.append,
}
]">
    <template v-if="type !== 'textarea'">
        <input 
        ref="input"
        v-bind="$attrs" 
        :type="type" 
        :disabled="disabled"
        :readonly="readonly"
        @focus="handleFocus"
        @blur="handleBlur"
        @input="handleInput" />
        <div class="sh-input-group__append" v-if="$slots.append">
            <slot name="append"></slot>
        </div>
    </template>
    <textarea
      v-else
      ref="textarea"
      v-bind="$attrs"
      :disabled="disabled"
      :readonly="readonly"
      @input="handleInput"
    >
    </textarea>
</div>
</template>

<script>
export default {
    name: 'ShInput',
    props: {
        value: [String, Number],
        disabled: Boolean,
        readonly: Boolean,
        type: {
            type: String,
            default: 'text'
        },
    },
    data () {
        return {
            // hovering: false,
            // focused: false,
            isComposing: false, 
        }
    },
    computed: {
        nativeInputValue() {
            return this.value === null || this.value === undefined ? '' : String(this.value);
        },
    },
    methods: {
        focus() {
            this.getInput().focus()
        },
        blur() {
            this.getInput().blur()
        },
        handleBlur(event) {
            // this.focused = false
            this.$emit('blur', event)
        },
        handleFocus(event) {
            // this.focused = true;
            this.$emit('focus', event)
        },
        handleInput(event) {
            // should not emit input during composition
            // see: https://github.com/ElemeFE/element/issues/10516
            // if (this.isComposing) return;
            // hack for https://github.com/ElemeFE/element/issues/8548
            // should remove the following line when we don't support IE
            if (event.target.value === this.nativeInputValue) return;
            this.$emit('input', event.target.value);
            // ensure native input value is controlled
            // see: https://github.com/ElemeFE/element/issues/12850
            this.$nextTick(this.setNativeInputValue);
        },
        setNativeInputValue() {
            const input = this.getInput();
            if (!input) return;
            if (input.value === this.nativeInputValue) return;
            input.value = this.nativeInputValue;
        },
        getInput() {
            return this.$refs.input || this.$refs.textarea;
        },
    },
    created () {

    },
    mounted () {
        this.setNativeInputValue();
    },
    beforeDestroy () {
        
    },
    watch: {
        nativeInputValue() {
            this.setNativeInputValue();
        },
    }
}
</script>

<style lang="scss" scoped>
// @import '@/assets/styles/config.scss';

.sh-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 100%;
    & > input {
        -webkit-appearance: none;
        background-image: none;
        box-sizing: border-box;
        display: inline-block;
        line-height: 44px;
        outline: 0;
        transition: border-color .2s cubic-bezier(.645,.045,.355,1);
        width: 100%;
        height: 46px;
        padding: 0 17px;
        background-color: #0c1135;
        border-radius: 5px;
        box-shadow: inset 0px 0px 3px 0px #0a0d28;
        border: 0;
        font-size: 18px;
        color: #aab6e4;
    }
    & > textarea {
        display: block;
        resize: vertical;
        padding: 5px 15px;
        line-height: 1.5;
        box-sizing: border-box;
        width: 100%;
        border-radius: 4px;
        // transition: border-color .2s cubic-bezier(.645,.045,.355,1);
        background-color: #0c1135;
        box-shadow: inset 0px 0px 3px 0px #0a0d28;
        border: 0;
        font-size: 18px;
        color: #aab6e4;
    }
    &-group {
        line-height: normal;
        display: inline-table;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        input {
            vertical-align: middle;
            display: table-cell;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        &__append {
            vertical-align: middle;
            display: table-cell;
            position: relative;
            border-radius: 4px;
            // padding: 0 20px;
            width: 1px;
            white-space: nowrap;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            background-image: linear-gradient(90deg, #009dff, #33eb89);
            button {
                background-color: transparent;
                color: #ffffff;
                font-size: 18px;
                font-weight: bold;
                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
}
</style>
